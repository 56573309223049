import React from 'react';
import cn from 'classnames';

import Images from '@components/Images';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import styles from './InformationBanner.module.scss';

interface BannerArgs {
  title: string;
  description: string;
  image: string | IGatsbyImageData;
  textColor?: string;
  imagePosition?: string;
}

interface InformationBannerArgs {
  banner: BannerArgs;
  twoCols?: boolean;
}

/* 
  TODO: беда с типами. Нужно рефакторить.
  Поменял тип в image на string | IGatsbyImageData (пока везде используется при вызовах string)
*/
function InformationBanner({ banner, twoCols = false }: InformationBannerArgs) {
  return (
    <div className={styles.informationBanner}>
      <div
        className={cn({
          [styles.banner]: true,
          [styles.twoCols]: twoCols,
        })}
      >
        {typeof banner.image !== 'string' ? (
          <GatsbyImage
            alt="фотография баннера"
            className={styles.image}
            imgStyle={{ objectPosition: banner.imagePosition }}
            image={banner.image}
          />
        ) : (
          <Images className={styles.image} src={banner.image} alt="фотография баннера" />
        )}
        <div className={styles.metadata}>
          {banner.title && (
            <h1
              className={styles.title}
              style={{ color: banner.textColor }}
              dangerouslySetInnerHTML={{ __html: banner.title }}
            />
          )}
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: banner.description }}
          />
        </div>
      </div>
    </div>
  );
}

export default InformationBanner;
