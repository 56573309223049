import React from 'react';

import { useProducts } from '@hooks/useProducts';

import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';
import ProductsBlock from '@components/Blocks/ProductsBlock';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Заказать фотокарточки"
        description="Закажите фотокарточки: открытки или фото в стиле полароидных снимков на память. Приятно дарить, подписывать и украшать интерьер!"
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { printsProducts } = useProducts();

  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Карточки',
          description: ` <p>
        Напечатайте открытки или фото в стиле полароидных снимков на память. Такой формат отлично подойдет для кадров с дружеских встреч и путешествий.
      </p>
      <br/>
      <p>
      Карточками можно украсить стены в квартире, вложить их в букет цветов или подарить близкому человеку. На обратной стороне вертикальных открыток есть место для марки и адреса, чтобы вы смогли отправить их родным из других городов и стран.
      </p>
      <br/>
      <p>
      При заказе карточек сегодня получите в Москве и СПб через 3-4 рабочих дня. В других городах — от 4 рабочих дней. 
      </p>
      `,
          image: 'card-red.jpg',
          textColor: 'white',
        }}
        twoCols
      />

      <ProductsBlock products={printsProducts} />
    </Layout>
  );
}

export default ProductsPage;
