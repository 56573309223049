import React from 'react';
import cn from 'classnames';
import { graphql } from 'gatsby';

import { ProductCard } from './ProductCard';

import styles from './Products.module.scss';

export function Products({ products }) {
  return (
    <div className={styles.block}>
      <div
        className={cn({
          [styles.products]: true,
          [styles.flexStub]: products.length % 4 !== 0,
          [styles.flexStubAll]: (products.length + 2) % 4 === 0,
        })}
      >
        {products.length ? (
          products.map((product) => (
            <ProductCard
              product={product}
              className={styles.product}
              key={`product-${product.url}`}
            />
          ))
        ) : (
          <div className={styles.notFound}>Извините, но по вашему запросу ничего не найдено :(</div>
        )}
      </div>
    </div>
  );
}

// WHY IT IS HERE
// this query ignored
export const query = graphql`
  fragment ProductFields on STRAPI_PRODUCT {
    id
    key
    type
    productType
    innerType
    title
    subtitle
    description
    filters {
      id
      coverType
      pageCount {
        id
        strapi_json_value
      }
      productFormat
    }
    rank
    realPrice
    salePrice
    labels {
      id
      name
    }
    url
    image {
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    actionUrl
    actionTitle
    options {
      id
      key
      value
    }
    variants {
      ... on STRAPI__COMPONENT_PRODUCT_COMPONENTS_VARIANTS {
        id
        title
        variantValues {
          value
          actionUrl
          realPrice
          image {
            localFile {
              publicURL
            }
          }
          activeImage {
            localFile {
              publicURL
            }
          }
        }
        type
      }
      ... on STRAPI__COMPONENT_PRODUCT_COMPONENTS_VARIANT {
        id
        title
        variantValues {
          title
          color
          product {
            url
          }
        }
        type
      }
    }
    promos {
      id
      title
      type
      promocode
    }
    services {
      id
      title
      subtitle
      miniSubtitle
      color
      url
      price
      icon {
        localFile {
          publicURL
        }
      }
    }
    gallery {
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    recommendations {
      id
      key
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      salePrice
      realPrice
      title
      subtitle
      url
      labels {
        id
        name
      }
    }
    isUnavailable
    preOrder
    preorderMetadata {
      endDate
      certificateDuration
    }
    googleProductCategory
    fbProductCategory
    actionDisabled
  }
`;
