import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import styles from './Header.module.scss';

export function Header({ littleHeader, children }) {
  return (
    <h1
      className={cn({
        [styles.header]: true,
        [styles.littleHeader]: littleHeader,
      })}
    >
      {children}
    </h1>
  );
}

Header.propTypes = {
  children: propTypes.node.isRequired,
  littleHeader: propTypes.bool,
};

Header.defaultProps = {
  littleHeader: false,
};
